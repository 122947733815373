import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

// Styles
import "../styles/custom.scss"
import footerStyles from "./footer.module.scss"


export default () => (
    <StaticQuery
        query={
            graphql`
                query {
                    logo: file(relativePath: { regex: "/logo_transparent/" }) {
                        childImageSharp {
                            fixed (
                                width: 50
                                height: 50
                                fit: CONTAIN
                                background: "white"
                            ) 
                            {
                                ...GatsbyImageSharpFixed
                                src
                            }
                        }
                    }
                }
            `
        }
        render={(data) => (<Footer data={data} />)}
    />
)

class Footer extends React.Component {
    // state = {
    //     isActive: false,
    //     showOfficeHours: false,
    // }

    render() {
        return (
            <footer className={`${footerStyles.main}`}>
                <Container fluid className={`mainMid py-5`}>
                    <Row>
                        {/* Logo */}
                        <Col xs={6} md={3}>
                            <ul className={`${footerStyles.linkList}`}>
                                <div className={`${footerStyles.logo}`}>
                                    <img
                                        src={this.props.data.logo.childImageSharp.fixed.src}
                                    />
                                </div>
                                <p>
                                    Die Psychotherapiepraxis
                                    München - Laim
                            </p>
                            </ul>
                        </Col>
                        <Col xs={6} md={3}>
                            <ul className={`${footerStyles.linkList}`}>
                                <li className="pb-2"><strong>Über uns</strong></li>
                                <li><Link to="/team/">Team</Link></li>
                                <li><Link to="/angebot/">Angebot</Link></li>
                                <li><Link to="/praxis/">Praxis</Link></li>
                                <li><Link to="/kosten/">Kosten</Link></li>
                            </ul>
                        </Col>
                        {/* Öffnungszeiten */}
                        <Col xs={6} md={3}>
                            <ul className={`${footerStyles.linkList}`}>
                                <li className="pb-2"><strong>Kontakt</strong></li>
                                <li>
                                    <a href="mailto:info@ptp-m.de">info@ptp-m.de</a>
                                </li>
                                <li>
                                    <a href="tel:+49 89 12350646">
                                        +49 89 12350646
                                    </a>
                                </li>
                                <li>
                                    <a href="https://goo.gl/maps/q94aF3493JxiWxai7" target="_blank">
                                        Fürstenrieder Straße 95<br></br> 80686 München
                                    </a>
                                </li>
                            </ul>
                        </Col>
                        {/* Rechtliches */}
                        <Col xs={6} md={3}>
                            <ul className={`${footerStyles.linkList}`}>
                                <li className="pb-2"><strong>Rechtliches</strong></li>
                                <li><Link to="/datenschutz/">Datenschutz</Link></li>
                                <li><Link to="/impressum/">Impressum</Link></li>
                            </ul>
                        </Col>
                    </Row>

                    <Row>
                        <Col className={`${footerStyles.copyright} text-center my-3`}>
                            © {new Date().getFullYear()} <a className={`${footerStyles.initicsLink}`} href="https://initics.de">initics.de</a> - Design und Entwicklung. Alle Rechte vorbehalten
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}