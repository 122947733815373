import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

import Nav from "react-bootstrap/Nav"
import { Container, Row, Col } from "react-bootstrap"

// Styles
import "../styles/custom.scss"
import headerStyles from "./header.module.scss"


// Font Awesome
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaCalendarAlt } from "react-icons/fa"

export default () => (
    <StaticQuery
        query={
            graphql`
                query {
                    logo: file(relativePath: { regex: "/logo_transparent/" }) {
                        childImageSharp {
                            fixed (
                                width: 50
                                height: 50
                                fit: CONTAIN
                                background: "white"
                            ) 
                            {
                                ...GatsbyImageSharpFixed
                                src
                            }
                        }
                    }
                }
            `
        }
        render={(data) => (<Header data={data} />)}
    />
)

class Header extends React.Component {
    state = {
        isActive: false,
        showOfficeHours: false,
    }

    render() {

        const isActive = this.state.isActive
        const showOfficeHours = this.state.showOfficeHours

        return (
            <div>
                <div className={showOfficeHours ? `${headerStyles.officeHoursModal} d-block` : `${headerStyles.officeHoursModal} d-none`}
                    onClick={() => this.setState({ showOfficeHours: !showOfficeHours })}
                >
                    <Container className={`${headerStyles.officeHoursModalContent}`}>
                        <Row>
                            <Col className="text-left my-3">
                                <strong>Öffnungszeiten</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Montag: 9:00 - 18:00 Uhr
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Dienstag: 9:00 - 18:00 Uhr
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Mittwoch: 9:00 - 18:00 Uhr
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Donnerstag: 9:00 - 18:00 Uhr
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Freitag: 9:00 - 18:00 Uhr
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Samstag: geschlossen
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Sonn- und Feiertags: geschlossen
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Nav>
                    <div className={`${headerStyles.infoPanel} d-flex align-items-center`}>
                        <div className="p-2 pl-4 flex-grow-1">
                            <FaMapMarkerAlt size={24} class="pb-1" />
                            <a href="https://goo.gl/maps/q94aF3493JxiWxai7" className="noLinkStyling" target="_blank">
                                <span className={`p-2`}>
                                    Fürstenrieder Straße 95, 80686 München
                                </span>
                            </a>
                        </div>

                        <div className={`p-2`}>
                            <FaEnvelope size={24} class="pb-1" />
                            <a href="mailto:info@ptp-m.de" className="noLinkStyling">
                                <span className={`pl-2`}>
                                    info@ptp-m.de
                                </span>
                            </a>
                        </div>
                        <span>|</span>
                        <div className={`p-2`}>
                            <FaPhone size={24} class="pb-1" />
                            <a href="tel:+49 89 12350646" className="noLinkStyling">
                                <span className="pl-2">
                                    +49 (0) 89 12350646
                                </span>
                            </a>
                        </div>
                        <span>|</span>
                        <div
                            className={showOfficeHours ? `${headerStyles.cursorHover} p-2` : `${headerStyles.cursorHover} cursorHover p-2 `}
                            onClick={() => this.setState({ showOfficeHours: !showOfficeHours })}
                        >
                            <FaCalendarAlt size={24} class="pb-1" />
                            <span className={`px-2`}>
                                Öffnungszeiten
                        </span>
                        </div>
                    </div>
                    <div>
                        <Link to="/">
                            <img className={headerStyles.logoMobile}
                                src={this.props.data.logo.childImageSharp.fixed.src}
                            />
                        </Link>
                    </div>
                    <div id="mainListDiv"
                        className={isActive ? "mx-auto main_list show_list" : "mx-auto main_list"}
                    >
                        <ul>
                            <li className={headerStyles.link}><Link to="/">HAUPTSEITE</Link></li>
                            <li className={headerStyles.link}><Link to="/angebot/">ANGEBOT</Link></li>
                            <li><Link to="/team/">TEAM</Link></li>
                            {/* Logo */}
                            <li className={`${headerStyles.logoBox} text-center`}>
                                <Link to="/">
                                    <img
                                        className={`${headerStyles.logo}`}
                                        src={this.props.data.logo.childImageSharp.fixed.src}
                                    />
                                </Link>
                            </li>
                            <li><Link to="/praxis/">PRAXIS</Link></li>
                            <li><Link to="/kosten/">KOSTEN</Link></li>
                            <li><Link to="/kontakt/">KONTAKT</Link></li>
                            {/* Öffnungszeiten */}
                            <div className={`${headerStyles.officeHours}`}>
                                <Row>
                                    <Col className="text-left">
                                        <strong>Öffnungszeiten</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Montag: 9:00 - 18:00 Uhr
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Dienstag: 9:00 - 18:00 Uhr
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Mittwoch: 9:00 - 18:00 Uhr
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Donnerstag: 9:00 - 18:00 Uhr
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Freitag: 9:00 - 18:00 Uhr
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Samstag: geschlossen
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Sonn- und Feiertags: geschlossen
                                    </Col>
                                </Row>
                                <Row className={`my-3`}>
                                    <a href="mailto:info@ptp-m.de" className={`primaryBtn text-center`}>
                                        TERMIN VEREINBAREN
                                    </a>
                                </Row>
                            </div>
                            <div className={`${headerStyles.infoPanelMobile} show-mobile d-flex align-items-center`}>
                                {/* Address */}
                                <div className="p-2 flex-grow-1 text-left">
                                    <FaMapMarkerAlt size={24} class="pb-1" />
                                    <a href="https://goo.gl/maps/q94aF3493JxiWxai7" className="noLinkStyling" target="_blank">
                                        <span>
                                            Fürstenrieder Straße 95, 80686 München
                                        </span>
                                    </a>
                                </div>
                                {/* Mail */}
                                <a href="mailto:info@ptp-m.de">
                                    <div className="p-2">
                                        <FaEnvelope size={24} class="pb-1" />
                                    </div>
                                </a>
                                {/* Call */}
                                <a href="tel:+49 89 12350646" className="noLinkStyling">
                                    <div className="p-2">
                                        <FaPhone size={24} class="pb-1" />
                                    </div>
                                </a>
                            </div>

                        </ul>
                    </div>
                    <span
                        className={isActive ? "navTrigger active" : "navTrigger"}
                        onClick={() => this.setState({ isActive: !isActive })}
                    >
                        <i></i>
                        <i></i>
                        <i></i>
                    </span>
                </Nav >
            </div >
        )
    }
}